/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-05-06 15:49:45
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2024-03-10 14:07:18
 * @FilePath: \weapp\wepay-h5\src\pages\home\Home.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, {memo, useEffect, useState} from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import {Swiper, SpinLoading} from "antd-mobile";
import styles from './Home.module.scss';
import {useTranslation} from "react-i18next";
import HomeNavBar from "@/components/homeNavBar/HomeNavBar";
import HomeContent from "@/layouts/HomeContent/HomeContent";
import CommonButton from "@/components/commonButton/CommonButton";
import homeApi from '@/service/homeApi';
import CommonPopover from "@/components/commonPopover/CommonPopover";
import { tokenName } from '@/utils/walletConfig';

import { ReactComponent as IconMiningValue } from '@icon/mining_value.svg';
import { ReactComponent as IconEarnings } from '@icon/earnings.svg';
import { ReactComponent as IconInfo } from '@icon/info.svg';
import { ReactComponent as IconRank } from '@icon/rank.svg';

export default memo(function Home() {
  let {t} = useTranslation();
  let navigate = useNavigate();
  let location = useLocation();

  // 轮播图
  const [imgBanner, setImgBanner] = useState([]);
  // GAP价格
  const [gapPrice, setGapPrice] = useState(0);
  // 我的产能值
  const [myPower, setMyPower] = useState(0);
  // 累计收益
  const [totalIncome, setTotalIncome] = useState(0);
  // 团战排行榜
  const [rankList, setRankList] = useState([]);
  // NFT升级购买
  const [nftUpgrade, setNftUpgrade] = useState([]);
  // 加载中
  const [loading, setLoading] = useState(true);
  const [carouselTime, setCarouselTime] = useState(3000);
  
  // GAP图标
  const CoinGAP = (props) => {
    return (
      <img src={require('@/assets/images/coin/gap.png')} width={props.width} height={props.height} alt=""/>
    )
  }

  // 获得首页数据
  const getHomeData = async () => {
    setLoading(true);
    let res = await homeApi.getHomeData();
    setLoading(false);
    const { price, nfts, ranking, images,carouselImageTime } = res.data;
    // 轮播图
    setImgBanner(images);
    // GAP价格
    setGapPrice(price);
    // 我的产能值
    setMyPower(nfts.signPower);
    // 累计收益
    setTotalIncome(nfts.totalEarns);
    // NFT升级购买
    setNftUpgrade(nfts.upgradeLogs);
    // 排行榜
    setRankList(ranking);
    // 轮播图时间
    setCarouselTime(carouselImageTime*1000)
  }

  useEffect(() => {
    getHomeData();
  }, [])

  return (
    <HomeContent header={<HomeNavBar fixed={null}></HomeNavBar>}>
      {/* 轮播图 */}
      <div className={styles.swiperWrapper}>
        {
          loading ? (
            <SpinLoading />
          ) : (
            <Swiper 
              autoplay
              autoplayInterval={carouselTime}
              loop={true}
              indicatorProps={{
                color: 'white',
              }}
            >
              {
                imgBanner.map((item, index) => {
                  return (
                    <Swiper.Item key={index}>
                      <a className={styles.swiperItem} href={item.url} target="_blank" rel="noreferrer">
                        <img src={item.image || require('@/assets/images/banner1.jpg')} alt=""/>
                      </a>
                    </Swiper.Item>
                  )
                })
              }
            </Swiper>
          )
        }
      </div>
      <div className={styles.main}>
        {/* GAP价格 1 GAP = 0.00312 USDT */}
        <div className={styles.gapPrice}>
          {/* 左侧显示币图标 1 GAP = 0.00312 USDT */}
          <div className={styles.gapPriceLeft}>
            <img src={require('@/assets/images/coin/gap.png')} alt=""/>
            <div>
              <div className={styles.label}>{tokenName}{t('lbl_price')}</div>
              <span className={styles.price}>1 {tokenName} = {gapPrice} USDT</span>
            </div>
          </div>
          {/* 右侧显示充值按钮 */}
          <div className={styles.gapPriceRight}>
            <CommonButton small className={styles.btnRecharge} onClick={() => navigate('/mine/wallet/recharge')}><img src={require('@/assets/images/dollar.png')} width={14} alt="" />{t('lbl_recharge')}</CommonButton>
          </div>
        </div>
        <div className="h10"></div>
        {/* 推荐NFT升级购买 */}
        {
          loading ? (
            <div className={styles.recommendNull}>
              <SpinLoading />
            </div>
          ) : (
            nftUpgrade.length > 0 ? (
              <div className={styles.recommend}>
                <img src={nftUpgrade[nftUpgrade.length -1].image || require('@/assets/images/1.jpg')} alt=""/>
                <div className={styles.recommendItemInfo}>
                  <div className={styles.recommendItemInfoTitle}>{nftUpgrade[nftUpgrade.length -1].name}</div>
                  <div className={styles.recommendItemInfoPrice}>
                    {/* 美元图标 */}
                    <CoinGAP width={44} height={44} />
                    <div>{nftUpgrade[nftUpgrade.length -1].price}</div>
                  </div>
                  {/* 升级按钮 */}
                  <CommonButton small className={styles.btnBuy} onClick={() => navigate('/shop')}>{t('lbl_upgrade')}</CommonButton>
                </div>
              </div>
            ) : (
              <>
                <CommonButton shadow className={styles.btnBuy} onClick={() => navigate('/shop')}>{t('lbl_go_to_buy_nft')}</CommonButton>
                <div className="h10"></div>
              </>
            )
          )
        }
        <div className="h10"></div>
        {/* 我的产能值 和 累计收益 */}
        <div className={styles.myPower}>
          <div className={styles.myPowerItem}>
            {/* 我的产能值 */}
            <div className={styles.myPowerItemLeft}>
              <IconMiningValue width={44} height={44} color="#C2D6ED" />
            </div>
            <div className={styles.myPowerItemRight}>
              <CommonPopover content={t('lbl_produce_introduce', {tokenName: tokenName})}>
                <div className={styles.myPowerItemTitle}>{t('lbl_my_produce')}&nbsp;<IconInfo width={12} height={12} color="#C2D6ED" className={styles.IconInfo} /></div>
              </CommonPopover>
              <div className={styles.myPowerItemNum}>{myPower} W</div>
            </div>
          </div>
          <div className={styles.myPowerItem}>
            {/* 累计收益 */}
            <div className={styles.myPowerItemLeft}>
              <IconEarnings width={44} height={44} color="#C2D6ED" />
            </div>
            <div className={styles.myPowerItemRight}>
              <div className={styles.myPowerItemTitle}>{t('lbl_total_income')}</div>
              <div className={styles.myPowerItemNum}>{totalIncome} {tokenName}</div>
            </div>
          </div>
        </div>
        {/* 团战排行榜 */}
        <div className={styles.rank}>
          {/* 标题 */}
          <div className={styles.rankTitle}>
            <IconRank width={20} height={20} />
            <span>{t('lbl_team_ranking')}</span>
          </div>
          {
            !loading && rankList.length === 0 && (
              <div className={styles.rankEmptyText}>{t('common.lbl_no_data')}</div>
            )
          }
          {
            rankList.length > 0 && (
              <div className={styles.rankTop3}>
                <div className={`${styles.rankTop3Item} ${styles.two}`}>
                  {
                    rankList[1] && (
                      <>
                        {/* 第二名图标 */}
                        <img src={require('@/assets/images/rank_two.png')} width={20} alt="" />
                        <div className={styles.rankTop3ItemRank}>{t('lbl_second')}</div>
                        <div className={styles.rankTop3ItemInfo}>
                          {/* 用户头像 */}
                          <img src={rankList[1].teamImage || require('@/assets/images/user.png')} alt="" className={styles.rankTop3ItemInfoAvatar} />
                          <div className={styles.rankTop3ItemInfoName}>{rankList[1].teamName || '孟菲斯'}</div>
                        </div>
                      </>
                    )
                  }
                </div>
                <div className={`${styles.rankTop3Item} ${styles.one}`}>
                  {
                    rankList[0] && (
                      <>
                        {/* 第一名图标 */}
                        <img src={require('@/assets/images/rank_one.png')} width={24} alt="" />
                        <div className={styles.rankTop3ItemRank}>{t('lbl_first')}</div>
                        <div className={styles.rankTop3ItemInfo}>
                          {/* 用户头像 */}
                          <img src={rankList[0].teamImage || require('@/assets/images/user.png')} alt="" className={styles.rankTop3ItemInfoAvatar} />
                          <div className={styles.rankTop3ItemInfoName}>{rankList[0].teamName || '孟菲斯'}</div>
                        </div>
                      </>
                    )
                  }
                </div>
                <div className={`${styles.rankTop3Item} ${styles.three}`}>
                  {
                    rankList[2] && (
                      <>
                        {/* 第三名图标 */}
                        <img src={require('@/assets/images/rank_three.png')} width={20} alt="" />
                        <div className={styles.rankTop3ItemRank}>{t('lbl_third')}</div>
                        <div className={styles.rankTop3ItemInfo}>
                          {/* 用户头像 */}
                          <img src={rankList[2].teamImage || require('@/assets/images/user.png')} alt="" className={styles.rankTop3ItemInfoAvatar} />
                          <div className={styles.rankTop3ItemInfoName}>{rankList[2].teamName || '孟菲斯'}</div>
                        </div>
                      </>
                    )
                  }
                </div>
              </div>
            )
          }
          {
            rankList.length > 3 && (
              <div className={styles.rankOther}>
                {
                  rankList[3] && (
                    <div className={styles.rankOtherItem}>
                      <div className={styles.rankOtherItemRank}>4</div>
                      <div className={styles.rankOtherItemInfo}>
                        {/* 用户头像 */}
                        <img src={rankList[3].teamImage || require('@/assets/images/user.png')} alt="" className={styles.rankOtherItemInfoAvatar} />
                        <div className={styles.rankOtherItemInfoName}>{rankList[3].teamName || '孟菲斯'}</div>
                      </div>
                    </div>
                  )
                }
                {
                  rankList[4] && (
                    <div className={styles.rankOtherItem}>
                      <div className={styles.rankOtherItemRank}>5</div>
                      <div className={styles.rankOtherItemInfo}>
                        {/* 用户头像 */}
                        <img src={rankList[4].teamImage || require('@/assets/images/user.png')} alt="" className={styles.rankOtherItemInfoAvatar} />
                        <div className={styles.rankOtherItemInfoName}>{rankList[4].teamName || '孟菲斯'}</div>
                      </div>
                    </div>
                  )
                }
              </div>
            )
          }
        </div>
      </div>
    </HomeContent>
  );
})