/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-12-06 09:09:49
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-12-14 17:39:28
 * @FilePath: \weappf:\Workspace\NEW-WEB\neww-web-front\src\pages\battlefield\Battlefield.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { memo, useState, useRef, useEffect, forwardRef } from 'react';
import styles from './Community.module.scss';
import { useTranslation } from "react-i18next";

import CommonPage from "components/commonPage/page/CommonPage";
import CommonNavBar from "components/commonNavBar/CommonNavBar";
import {CopyToClipboard} from "react-copy-to-clipboard/src";
import {toastLoading, toastHide} from "utils/Toast";
import mineApi from '@/service/mineApi';
import moment from "moment";
import CommonPageScrollList from "@/components/commonPage/ScrollList/CommonPageScrollList";
import {ReactComponent as IconCopy } from '@icon/Copy.svg';
import {ReactComponent as IconSetting} from '@icon/Setting.svg';
import {ReactComponent as IconRecord} from '@icon/Record.svg';
import {ReactComponent as IconFilter} from '@icon/Filter.svg';
import {ReactComponent as IconCollapseShow} from '@icon/collapseShow.svg';
import {ReactComponent as IconCollapseHide} from '@icon/collapseHide.svg';
import { useNavigate } from 'react-router-dom'; // 用于路由跳转
import CommonPopup from "components/commonPopup/CommonPopup";
import FilterItemLayout from "@/components/filterItemLayout/FilterItemLayout";
import CommonInput from "components/commonInput/CommonInput";
import CommonButton from "components/commonButton/CommonButton";
import CommonModal from 'components/commonModal/CommonModal';
import {Form} from 'antd-mobile';
import { formatString } from '@/utils/utils';


export default memo(function Community() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const refList = useRef(null);
  const { t } = useTranslation();
  const [member, setMember] = useState([]); // 团队信息
  const [teamNum, setTeamNum] = useState([]); // 团队人数
  const [subordinateList, setSubordinateList] = useState([{}]); // 下级列表
  const [expandedLists, setExpandedLists] = useState({}); // 展开的列表
  const [searchUserId, setSearchUserId] = useState(''); // 搜索用户ID
  // 是否弹出ID查询的弹窗
  const [showIdSearchPopup, setShowIdSearchPopup] = useState(false);
  // 是否弹出查看级别业绩的弹窗
  const [isShowFormanceModal, setIsShowFormanceModal] = useState(false);

  // 获取团队信息
  const getApiData = (page) => {
    toastLoading();
    mineApi.getMyTeam().then(res => {
      setMember(res.data);
    }).finally(() => {
      toastHide();
    })
  }

  // 获取我的团队列表
  const getTeamList = (userId) => {
    toastLoading();
    mineApi.getMyTeamList({
      userId: userId || ''
    }).then(res => {
      setSubordinateList(res.data.list);
    }).finally(() => {
      toastHide();
    })
  }

  // 获取我的团队下级列表
  const getSubordinateList = async (userId) => {
    toastLoading();
    await mineApi.getMyTeamSubList({
      userId
    }).then(res => {
      findChildren(subordinateList, userId, res.data.list);
    }).finally(() => {
      toastHide();
    })
  }

  // 递归查询subordinateList，找到对应的userId，然后把children赋值 res.data.list
  // 递归查询
  const findChildren = (list, userId, children) => {
    list.forEach(item => {
      if (item.id === userId) {
        item['children'] = children;
      } else {
        if (item.children && item.children.length > 0) {
          findChildren(item.children, userId, children);
        }
      }
    })
    setSubordinateList(list);
  }

  // 根据id递归查询subordinateList，判断是否有children
  const hasChildren = (list, id) => {
    let has = false;
    list.forEach(item => {
      if (item.id === id) {
        if (item.children && item.children.length > 0) {
          has = true;
        }
      } else {
        if (item.children && item.children.length > 0) {
          has = hasChildren(item.children, id);
        }
      }
    })
    return has;
  }

  // 展开收起下级列表
  const toggleExpand = async (id) => {
    if(!expandedLists[id]) {
      if (!hasChildren(subordinateList, id)) {
        await getSubordinateList(id);
      }
      
    }
    setExpandedLists(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  // 重置搜索条件
  const resetFilterSearchID = () => {
    setSearchUserId('')
  }
  
  const handleConfirmSearchID = () => {
    setShowIdSearchPopup(false);
    setSearchUserId('');
    getTeamList(searchUserId);
  }

  const handleCancelSearchPopup = () => {
    setShowIdSearchPopup(false);
  }

  const handleCancelFormanceModal = () => {
    setIsShowFormanceModal(false);
  }

  const onFinishFormFormance = (values) => {
    navigate('/mine/community/search', {state: {level: values.level}});
  }

  const changeSearchID = (val, key) => {
    setSearchUserId(val);
  };

  useEffect(() => {
    getApiData();
    getTeamList();
  }, [])

  // 查看级别业绩的表单
  const FormFormance = forwardRef(() => {
    return (
      <Form className={styles.form} form={form} onFinish={onFinishFormFormance} footer={
        <div className={styles.btnGroup}>
          <CommonButton block cancel onClick={() => handleCancelFormanceModal()}>{t('common.cancel')}</CommonButton>
          <CommonButton block type='submit'>{t('common.confirm')}</CommonButton>
        </div>
      }>
        <Form.Item name="level" rules={[
          {
            validator: (rule, value) => {
              if (!value) {
                return Promise.reject(t('msg_input_level'));
              } else {
                // 只能是正整数
                if (!/^[1-9]\d*$/.test(value)) {
                  return Promise.reject(t('msg_input_level'));
                } else {
                  // 不能超过最大级别
                  if (value > member.maxLevel) {
                    return Promise.reject(t('msg_level_over_max', {level: member.maxLevel}));
                  } else {
                    return Promise.resolve();
                  }
                }
              }
            }
          }
          ]}>
          <CommonInput type={'text'} placeholder={t('lbl_input_level')} />
        </Form.Item>
        <div className={styles.maxLevelTip}>{t('lbl_max_level', {level: member.maxLevel})}</div>
      </Form>
    )
  })

  const renderSubordinateLevel = (level, ancestralId) => {
    return (
      <div className={`${styles.teamTitle} ${styles.small}`}>{t('lbl_subordinate_level', {level: level})}({t('lbl_user_id')}: {ancestralId})</div>
    )
  }

  // 递归渲染下级列表
  const renderSubordinateList = (list, level=1) => {
    return (
      <>
        {list.length > 0 && list[0].level > 1 && renderSubordinateLevel(list[0].level, list[0].ancestralId)}
        {list.map((item, index) => {
          return (
            <div className={styles.subordinateItemWraper} key={index}>
              <div className={`${styles.subordinateItem} ${item.level > 1 && styles.subordinateItemChildren} ${list[0].level % 2 === 0 && styles.even}`}>
                <div className={styles.subordinateItemTop}>
                  {/* 展开 折叠 按钮 Collapse */}
                  <div className={styles.subordinateItemTopL}>
                    {
                      item.totalNum > 0 && (
                        <div className={styles.btnCollapse} onClick={() => toggleExpand(item.id)}>
                          {/* 点击按钮 展开收起下级列表 */}
                          {expandedLists[item.id] ? <IconCollapseHide width={10} /> : <IconCollapseShow width={10} />}
                        </div>
                      )
                    }
                    {/* {item.nickname} 最多显示10个字符，超出显示... */}
                    <span>{formatString(item.nickname, 15)} ({item.totalNum}人)</span>
                  </div>
                  <div className={styles.subordinateItemTopR}>
                    <span>{t('lbl_user_id')}: {item.id}</span>
                  </div>
                </div>
                <div className={styles.subordinateItemBottom}>
                  <div className={styles.subordinateItemBottomItem}>
                    <div className={styles.subordinateItemLabel}>{t('lbl_my_subordinate_performance_total')}</div>
                    <div className={styles.subordinateItemValue}>{item.totalPerformance} USDT</div>
                  </div>
                  <div className={styles.subordinateItemBottomItem}>
                    <div className={styles.subordinateItemLabel}>{t('lbl_my_consume')}</div>
                    <div className={styles.subordinateItemValue}>{item.buyAssets} USDT</div>
                  </div>
                </div>
              </div>
              {
                item.children && item.children.length > 0 && (
                  <>
                    <div className={`${styles.childrenList} ${expandedLists[item.id] ? styles.show : styles.hide}`}>
                      {
                        item.children && renderSubordinateList(item.children)
                      }
                    </div>
                  </>
                )
              }
            </div>
          )
        })}
      </>
    )
  }

  return (
    <CommonPage tt={t('lbl_my_community')} flex header={
      <CommonNavBar right={member.isTeam === 1 && <IconSetting className={styles.btnSetting} onClick={() => navigate(`/mine/community/setting`)} />}>{t('lbl_my_community')}</CommonNavBar>
    }>
      {
        member.isTeam === 1 && (
          <div className={styles.teamTop}>
            <div className={styles.avatarBox}>
              {
                member && member.teamImage && (
                  <img className={styles.avatar} src={member.teamImage} alt='' />
                )
              }
            </div>
            <div className={styles.teamName}>{member.teamName}</div>
          </div>
        )
      }
      {/* 我的下级业绩 展示我的下级总人数 下级总累计消费金额 */}
      <div className={styles.teamTitle}>
        {t('lbl_my_subordinate_performance')}
        <IconRecord width={20} onClick={() => setIsShowFormanceModal(true)} />
      </div>
      <div className={styles.teamInfo}>
        <div className={styles.teamInfoItem}>
          <div className={styles.teamInfoItemLabel}>{t('lbl_my_subordinate_num')}</div>
          <div className={styles.teamInfoItemValue}>{member.totalNum}人</div>
        </div>
        <div className={styles.teamInfoItem}>
          <div className={styles.teamInfoItemLabel}>{t('lbl_my_subordinate_consume')}</div>
          <div className={styles.teamInfoItemValue}>{member.totalMoney} USDT</div>
        </div>
      </div>
      {/* 我的下级列表 */}
      <div className={styles.teamTitle}>
        {t('lbl_my_subordinate_list')}
        <IconFilter width={20} onClick={() => setShowIdSearchPopup(true)} />
      </div>
      {/* 展示引荐人数 引荐累计消费金额 */}
      <div className={`${styles.teamInfo} ${styles.small}`}>
        <div className={styles.teamInfoItem}>
          <div className={styles.teamInfoItemLabel}>{t('lbl_my_referrer_num')}</div>
          <div className={styles.teamInfoItemValue}>{member.totalPNum}人</div>
        </div>
        <div className={styles.teamInfoItem}>
          <div className={styles.teamInfoItemLabel}>{t('lbl_my_referrer_consume_total')}</div>
          <div className={styles.teamInfoItemValue}>{member.totalPMoney} USDT</div>
        </div>
      </div>
      {/* 下级列表 显示昵称 下级总业绩 本人累计消费金额 */}
      <div className={styles.subordinateList}>
        {
          renderSubordinateList(subordinateList)
        }
      </div>
{/*       <CommonPageScrollList
        ref={refList}
        renderListItem={renderListItem}
        getApiData={getApiData}>
      </CommonPageScrollList> */}
      {/* ID查询 */}
      <CommonPopup visible={showIdSearchPopup} getVisible={handleCancelSearchPopup}>
        <FilterItemLayout tt={'ID查询'} title={t('lbl_id_search')}>
          <CommonInput
            textAlign={'center'}
            layoutType={'filter'}
            placeholder={t('lbl_input_subordinate_id')}
            type={'number'}
            onChange={val => { changeSearchID(val) }}
            value={searchUserId}
          >
          </CommonInput>
          <div className={styles.tip}>{t('lbl_id_search_tip')}</div>
        </FilterItemLayout>
        <div className={styles.actions}>
          <CommonButton cancel onClick={resetFilterSearchID}>{t('common.reset')}</CommonButton>
          <CommonButton onClick={handleConfirmSearchID}>{t('common.confirm')}</CommonButton>
        </div>
        <div className="h30"></div>
      </CommonPopup>
      {/* 查看级别业绩 */}
      <CommonModal
        title={t('lbl_check_level_performance')}
        visible={isShowFormanceModal}
        onClose={handleCancelFormanceModal}
        content={<FormFormance/>}
      >
      </CommonModal>
    </CommonPage>
  );
})  