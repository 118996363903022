import styles from './Create.module.scss';
import React, {useEffect, useRef, useState, memo} from 'react';
import { Form } from "antd-mobile";
import CommonButton from "@/components/commonButton/CommonButton";
import CommonInput from "@/components/commonPwdInput/commonInput";
import {useNavigate, useLocation} from "react-router-dom";
import CommonPage from "@/components/commonPage/page/CommonPage";
import accountApi from "@/service/accountApi";
import {toastError, toastHide, toastLoading, toastSuccess } from "@/utils/Toast";
import {checkName} from "@/utils/utils";
import {useTranslation, Trans} from "react-i18next";

export default memo(function Create() {
  const {mainInviteCode} = useLocation().state;
  let navigate = useNavigate();
  const {t} = useTranslation();
  const [form] = Form.useForm();

  const onFinish = (values) => {
    // 创建子账号
    registerAccount();
  }

  // 昵称验证 2-14位
  const nicknameValidator = (_, value) => {
    if (!value || checkName(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(t("msg_incorrect_nickname")));
  };

  const registerAccount = () => {
    form.validateFields().then(values => {
      toastLoading();
      accountApi.registerSubAccount({
        nickname: values.nickname,
        inviteCode: values.inviteCode
      })
      .then((res) => {
        toastHide();
        if(res.status === 200) {
          toastSuccess(t('msg_create_sub_account_success'));
          navigate('/account/management', { replace: true });
        }
      })
      .catch(err => {
        toastHide();
        console.log(err)
      });
    });
  }

  useEffect(() => {
    form.setFieldsValue({inviteCode: mainInviteCode});
  }, []);

  return (
    <CommonPage title={t('lbl_create_sub_account')} flex className={styles.createSubAccount}>
      <Form className={styles.form} form={form} onFinish={onFinish} footer={
        <>
        <div className="h25"></div>
        <CommonButton block type='submit' color='primary'>{t('common.confirm')}</CommonButton>
        </>
      }>
        {/* 昵称 */}
        <div className={styles.formItem}>
          <Form.Item 
            label={t('lbl_placeholder_nickname')}
            name={'nickname'}
            rules={[
              { required: true, message: t('msg_incorrect_nickname') },
              { validator: nicknameValidator }
            ]}
          >
            <CommonInput placeholder={t('lbl_placeholder_nickname_2_14')} iconLeft={null} />
          </Form.Item>
        </div>
        {/* 邀请码 */}
        <div className={styles.formItem}>
          <Form.Item
            label={t('lbl_placeholder_invite_code')}
            name="inviteCode"
            rules={[{ required: true, message: t('msg_empty_invite_code') }]}
          >
            <CommonInput placeholder={t('lbl_placeholder_invite_code')} />
          </Form.Item>
        </div>
      </Form>
      <div className={styles.tip}>{t('lbl_create_sub_account_tip')}</div>
    </CommonPage>
  );
})