/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-12-06 09:09:49
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2024-03-10 13:33:18
 * @FilePath: \weappf:\Workspace\NEW-WEB\neww-web-front\src\pages\battlefield\Battlefield.jsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, { memo, useEffect, useState, useRef } from 'react';
import {InfiniteScroll} from "antd-mobile";
import { useLocation, useNavigate } from "react-router-dom";
import styles from './Nft.module.scss';
import {toastError, toastHide, toastLoading, toastSuccess} from "utils/Toast";
import { useTranslation } from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import { Form } from "antd-mobile";
import nftApi from "service/nftApi";
import CommonPage from "components/commonPage/page/CommonPage";
import CommonNavBar from "components/commonNavBar/CommonNavBar";
import CommonPopup from 'components/commonPopup/CommonPopup';
import CommonInput from "components/commonInput/CommonInput";
import CommonSelector from "components/commonSelector/CommonSelector";
import CommonButton from "components/commonButton/CommonButton";
import IconR from "components/icon/nft/IconR"
import IconSR from "components/icon/nft/IconSR"
import IconSSR from "components/icon/nft/IconSSR"
import IconFilter from "components/icon/IconFilter"
import classNames from 'classnames';

export default memo(function Battlefield() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [filterVisible,setFilterVisible] = useState(false);
  const [param,setParam] = useState({pageNo:1,pageSize:20});

  const [list,setList] = useState([])
  const [total,setTotal] = useState(0);

  const changeParams = (value,key) => {setParam(prevState => { prevState[key] = value;return {...prevState}})}
  const [form] = Form.useForm();
  
  const rareType = useSelector(state =>  state.app.heroLevel);

  const sortType=[
    {label:t("lbl_sort_id_asc"),value:"1",filter:{"orderBy":"id","orderType":"ASC"}},
    {label:t("lbl_sort_id_desc"),value:"2",filter:{"orderBy":"id","orderType":"DESC"}},
    {label:t("lbl_sort_level_asc"),value:"3",filter:{"orderBy":"level","orderType":"ASC"}},
    {label:t("lbl_sort_level_desc"),value:"4",filter:{"orderBy":"level","orderType":"DESC"}},

  ];
  const nftType=[
    {label:t("nft.hero"),value:1},
    {label:t("nft.skin"),value:0},
  ];

  const getNftList = async()=>{ 
    toastLoading();
    return await nftApi.nftMyList(param).then(res => {
        const data = list.concat( res.data.list )
        setList( data )
        setTotal( res.data.total )
        toastHide();
    }).catch(err => {
        toastHide();
    })
    
  }

  const search = async () => {
    setList([])
    setFilterVisible(false) 
    const data = form.getFieldsValue(true);
    const sortIteme = sortType.find(item => item.value == (data.sort&& data.sort[0]))||{}
    setParam(prevState => { 
      prevState.pageNo=1;
      prevState.id=data.id;
      prevState.name=data.name;
      prevState.level= data.level?.join(',');
      prevState.type= data.type?.join(',');
      return {...prevState, ...sortIteme.filter }
    })
    setFilterVisible(false)
  }
  const more = async () => {
    setParam(prevState => { 
      prevState.pageNo++;
      return prevState
    })
    return getNftList();
  }
  const reset = async () => {
    form.resetFields();
  }
  const nftItem=(item)=>{ 
    const rare = item.type === 1? 
    rareType.find(items=>items.level === item.level )||rareType.find(items=>items.level === "1") :
    rareType.find(items=>items.level === "1")
    console.log(rare )
    const type = nftType.find(items=> items.value === item.type )
    const linkDetail= item.type === 1?
    ()=>navigate('hero',{state:{id:item.id,info:item}}):
    ()=>navigate('material',{state:{id:item.id,info:item}});
    return (
    <div className={classNames(styles.grid_item,styles[rare.style] )} onClick={linkDetail} style={{ "backgroundImage": `url(${item.image}),linear-gradient(180deg, #123556 0%, #071A2C 100%)` }}>

      <div className='flex algn-center justify-between text-12 align-center' >
        <span>{"#"+item.id}</span> 
        {item.type=="1" && rare.tag }
      </div>
      <div className='text-center flex flex-direction text-12'>
        <div>{item.name}</div>
      </div>
    </div> )
  }
  useEffect(() => {
    getNftList();
  }, []);

  useEffect(() => {
    const data = form.getFieldsValue(true)
    console.log( Object.keys(data).length >0)
    if(param.pageNo ===1  ){
      getNftList();
    }
  }, [param]);

  
  return (
      <CommonPage tt={t('lbl_battlefield')} flex header={<CommonNavBar right={
          <span className='flex align-center' onClick={()=>setFilterVisible(true)}><IconFilter /></span>
        }>{t('nft.myNFT')}</CommonNavBar>}
      >
        <div className={styles.grid}>
          {list.map(item=>nftItem(item))}
        </div>
        <InfiniteScroll loadMore={ more } hasMore={list.length < total} threshold={320}></InfiniteScroll>

        <CommonPopup visible={filterVisible} getVisible={setFilterVisible} footer={
            <div className={styles.action}>
              <CommonButton cancel onClick={reset}>{t('common.reset')}</CommonButton>
              <CommonButton shadow onClick={search}>{t('common.confirm')}</CommonButton>
            </div>
          }>
          <Form className={styles.form} form={form} onFinish={search} >
            <div className='popupContent'>
              <Form.Item label={t("nft.name")} name="name" rules={[]}>
                <CommonInput type='text'/>
              </Form.Item>
              <Form.Item label={t("nft.no")} name="id" rules={[]}>
                <CommonInput type='text'/>
              </Form.Item>
              <Form.Item label={t("nft.rare")} name="level" rules={[]}>
                <CommonSelector columns={2} options={rareType} fieldNames={{ label: 'name', value: 'level'}}></CommonSelector>
              </Form.Item>
              <Form.Item label={t("nft.sort")} name="sort" rules={[]}>
                <CommonSelector columns={2} options={sortType}></CommonSelector>
              </Form.Item>
            </div>
          </Form>
        </CommonPopup>
      </CommonPage>
    );
  })  