import React, {forwardRef, useEffect, useState} from 'react';
import {Input} from "antd-mobile";
import styles from './CommonPwdInput.module.scss';
import {LockOutline} from "antd-mobile-icons";
import classNames from "classnames";


const CommonInput = forwardRef(({prefix,suffix, placeholder,style,textAlign, ...rest}, ref) => {

    const inputStyle = classNames({
        'input-start': textAlign === 'start',
        'input-center': textAlign === 'center',
        'input-end': textAlign === 'end',
    });
    return (
        <div className={styles.password} style={style}>
            {prefix && (prefix) }
            <Input
                ref={ref}
                className={inputStyle}
                placeholder={placeholder}
                {...rest}
            />
            {suffix && (suffix)}
        </div>
    );
});

CommonInput.defaultProps = {
    // prefix: <LockOutline/>
}

export default CommonInput;