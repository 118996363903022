import React, {useEffect, useState} from 'react';
import styles from './CommonPage.module.scss';
import CommonNavBar from "../../commonNavBar/CommonNavBar";
import {SpinLoading} from "antd-mobile";

const CommonPage = (props) => {

    const {title, header, children, content2, padding0, flex, className, loading = false} = props;

    return (
        <div className={`${styles.body} ${className}`}>
            <div className={styles.header}>
                {
                    header ?
                        header :
                        <CommonNavBar>{title}</CommonNavBar>
                }
            </div>

            {
                loading ?
                    <div className={styles.loading}>
                        <SpinLoading color='primary' style={{'--size': '48px'}}/>
                    </div>
                    :
                    <div
                        className={`${styles.content} ${content2 ? styles.content2 : ''} ${padding0 ? '' : styles.padding} ${flex ? styles.flex : ''}`}>
                        {children}
                    </div>
            }

        </div>
    );
};


CommonPage.defaultProps = {
    title: '标题',
    content2: false,
    padding0: false,
}

export default CommonPage;