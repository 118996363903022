import React, {forwardRef, useEffect, useState} from 'react';
import {Input} from "antd-mobile";
import styles from './CommonPwdInput.module.scss';
import {LockOutline, EyeInvisibleOutline, EyeOutline} from "antd-mobile-icons";


const CommonPwdInput = forwardRef(({iconLeft, placeholder, ...rest}, ref) => {

    const [visible, setVisible] = useState(false)

    return (
        <div className={styles.password}>
            {
                iconLeft &&
                (
                    <div className={styles.icon}>
                        {
                            iconLeft
                        }
                    </div>
                )
            }

            <Input
                ref={ref}
                className={styles.input}
                placeholder={placeholder}
                type={visible ? 'text' : 'password'}
                {...rest}
            />
            <div className={styles.eye}>
                {!visible ? (
                    <EyeInvisibleOutline onClick={() => setVisible(true)}/>
                ) : (
                    <EyeOutline onClick={() => setVisible(false)}/>
                )}
            </div>
        </div>
    );
});

CommonPwdInput.defaultProps = {
    iconLeft: <LockOutline/>
}

export default CommonPwdInput;