import React from 'react';
import mineApi from "../service/mineApi";
import {useDispatch, useSelector} from "react-redux";
import BigNumber from "bignumber.js";

const UseUserApi = () => {
    let dispatch = useDispatch();

    const cardInfo= useSelector(state => state.user.cardCurrentInfo);

    const userAssetsData = () => {
        console.log('资产+卡')
        return mineApi.getAssets({cardId: cardInfo.id})
            .then(data => {
                let totalPledgeAmount = 0;
                let totalLockupAmount = 0;
                data.coinCredit.forEach(item => {
                    // totalPledgeAmount += item.pledgeAmount;
                    // totalLockupAmount += item.lockupAmount;
                    totalPledgeAmount = new BigNumber(totalPledgeAmount).plus(new BigNumber(item.pledgeAmount)).toFixed(6);
                    totalLockupAmount = new BigNumber(totalLockupAmount).plus(new BigNumber(item.lockupAmount)).toFixed(6);
                })
                data.totalPledgeAmount = totalPledgeAmount.replace(/\.?0+$/, '');
                data.totalLockupAmount = totalLockupAmount.replace(/\.?0+$/, '');

                return data;
            })
    }

    const homeData = () => {
        // console.log('首页+卡')
        // return defiApi.homeAssets(cardInfo.id)
    }

    const userBalance = () => {
        console.log('余额+卡')
        return mineApi.getBalance(cardInfo.id)
    }

    return {userAssetsData, homeData, userBalance};
};

export default UseUserApi;