import styles from './Management.module.scss';
import React, {useEffect, useRef, useState, memo} from 'react';
import {Form} from "antd-mobile";
import CommonNavBar from "@/components/commonNavBar/CommonNavBar";
import {useNavigate} from "react-router-dom";
import CommonPage from "@/components/commonPage/page/CommonPage";
import accountApi from "@/service/accountApi";
import { toastHide, toastLoading, toastSuccess } from "@/utils/Toast";
import { checkPassword} from "@/utils/utils";
import {useTranslation, Trans} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import md5 from "md5";
import CommonDialog from 'components/commonDialog/CommonDialog';
import { copyToClipboard } from "@/utils/utils";
import useUserHooks from "@/hooks/useUserHooks";
import { setToken} from "@/redux/store/user";
import {ReactComponent as IconAdd } from '@icon/Add.svg';
import {ReactComponent as IconSwitch } from '@icon/Switch.svg';
import {ReactComponent as IconCopy } from '@icon/Copy.svg';

export default memo(function Management() {
  let { initUserData, userLogOut } = useUserHooks();
  const [form] = Form.useForm()
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const {t} = useTranslation();

  // 加载数据指示器loading
  const [loading, setLoading] = useState(false);
  
  // 子账户列表
  const [subAccountList, setSubAccountList] = useState([{
    nickname: 'nickname1',
    address: '0xE6F0c56d0e95f54F08CC86043954Dd6c943f8a03',
    type: 1, // 1:主账户 2:子账户
  }, {
    nickname: 'nickname2',
    address: '0xE6F0c56d0e95f54F08CC86043954Dd6c943f8a03',
    type: 2, // 1:主账户 2:子账户
  }, {
    nickname: 'nickname3',
    address: '0xE6F0c56d0e95f54F08CC86043954Dd6c943f8a03',
    type: 2, // 1:主账户 2:子账户
  }]);

  // 当前账户
  const [currentAccount, setCurrentAccount] = useState({
    nickname: 'nickname',
    address: '0xE6F0c56d0e95f54F08CC86043954Dd6c943f8a03',
  });

  const onFinish = (values) => {
    // 修改密码
    changePassword();
  }

  // 密码验证
  const passwordValidator = (_, value) => {
    if (!value || checkPassword(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(t("msg_incorrect_password")));
  };

  // 修改密码
  const changePassword = async () => {
    let data = form.getFieldsValue(true);
    let params = {
      login_pwd: md5(data.login_pwd),
      confirm_pwd: md5(data.confirmPassword),
      p_account: data.p_account,
    }
    toastLoading();
    accountApi.changePassword(params).then(res => {
      toastHide();
      if (res.code === 200) {
        toastSuccess(t('msg_password_changed'));
        navigate('/account');
      }
    
    }).catch(err => {
      toastHide();
    })
  }
  
  // 切换使用账户为Nicknamename
  const switchText = (nickname) => {
    return (
      <div className={styles.switchText} style={{textAlign: 'center'}}>{t('lbl_switch_account_desc')}<br /><span>{nickname}</span></div>
    )
  }

  // 切换子账户
  const switchSubAccount = (item) => {
    CommonDialog({
      type: 'confirm',
      title: t('lbl_switch_account'),
      content: switchText(item.nickname),
      okText: t('common.confirm'),
      cancelText: t('common.cancel'),
      onConfirm: () => {
        // 如果切换得是主账户
        if (item.type === 1) {
          // 退出登录
          userLogOut();
          return;
        }
        else {
          // 切换子账户
          accountApi.subAccountLogin({
            nickname: item.nickname
          }).then(async (res) => {
            if (res.status === 200) {
              toastSuccess(t('msg_switch_account_success'));
              dispatch(setToken(res.data.token));
              await initUserData();
              getAccountList();
            }
          })
        }
      }
    })
  }

  // 获取账户列表
  const getAccountList = () => {
    setLoading(true);
    accountApi.accountManagement({
      pageNo: 1,
      pageSize: 999
    }).then(res => {
      setLoading(false);
      if (res.status === 200) {
        setSubAccountList(res.data.list);
        setCurrentAccount(res.data.member);
      }
    }).catch(err => {
      setLoading(false);
    })
  }

  useEffect(() => {
    getAccountList();
  }, []);

  return (
    <CommonPage loading={loading} title={t('lbl_account_management')} flex className={styles.management} header={<CommonNavBar right={<IconAdd className={styles.btnAdd} onClick={() => navigate(`/account/management/create`, {state: {mainInviteCode: currentAccount.mainInviteCode}})} />}>{t('lbl_account_management')}</CommonNavBar>}>
      <div className={styles.main}>
        {/* 当前账户 */}
        <div className={styles.currentAccount}>
          <div className={styles.groupTitle}>{t('lbl_current_account')}</div>
          <div className={styles.subAccountItem}>
            <div className={styles.label}>{currentAccount.nickname}{currentAccount.type === 1 && ` (${t('lbl_main_account')})`}</div>
            <div className={styles.value}>{currentAccount.bindAddress}
              <IconCopy className={styles.iconCopy} onClick={() => {
                copyToClipboard(currentAccount.bindAddress);
                toastSuccess(t('msg_copy_success'));
              }} />
            </div>
          </div>
        </div>
        {/* 子账户列表list */}
        <div className={styles.subAccount}>
          <div className={styles.groupTitle}>{t('lbl_account_list')}</div>
          {
            subAccountList.map((item, index) => {
              return (
                <div className={styles.subAccountItem}>
                  <div className={styles.label}>
                    <span>{item.nickname}{item.type === 1 && ` (${t('lbl_main_account')})`}</span>
                    <IconSwitch onClick={() => switchSubAccount(item)}/>
                  </div>
                  <div className={styles.value}>
                    {item.bindAddress}
                    <IconCopy className={styles.iconCopy} onClick={() => {
                      copyToClipboard(currentAccount.bindAddress);
                      toastSuccess(t('msg_copy_success'));
                    }} />
                  </div>
                </div>
              )
            })
          }
        </div>
      </div>
    </CommonPage>
  );
})