import styles from './Recharge.module.scss';
import React, {useEffect, useState, memo} from 'react';
import CommonPage from "@/components/commonPage/page/CommonPage";
import { toastSuccess } from "@/utils/Toast";
import {useTranslation} from "react-i18next";
import NetWorkChoose from "@/components/netWorkChoose/NetWorkChoose";
import NetCoinChoose from "@/components/netCoinChoose/NetCoinChoose";
import { SpinLoading } from "antd-mobile";
import {QRCodeSVG} from "qrcode.react";
import commonApi from "@/service/commonApi";
import {ReactComponent as IconCopy } from '@icon/Copy.svg';

export default memo(function Recharge() {
  const {t} = useTranslation();
  
  // 选择的主网
  const [selectedNet, setSelectedNet] = useState('');
  // 选择的币种
  const [selectedCoin, setSelectedCoin] = useState('');
  // 加载充值地址
  const [loadingRechargeAddress, setLoadingRechargeAddress] = useState(true);
  // 充值地址
  const [rechargeAddress, setRechargeAddress] = useState('');
  // 充值信息
  const [rechargeInfo, setRechargeInfo] = useState({});

  // 复制到剪贴板
  const copyToClipboard = async (textToCopy) => {
    try {
      const textArea = document.createElement('textarea');
      textArea.value = textToCopy;
      document.body.appendChild(textArea);
      textArea.select();
      document.execCommand('copy');
      document.body.removeChild(textArea);
      toastSuccess(t('msg_copy_success'));
    } catch (err) {
      console.error('无法复制文本到剪贴板', err);
    }
  };
  
  useEffect(() => {
  }, []);

  useEffect(() => {
    if(selectedCoin) {
      setLoadingRechargeAddress(true)
      commonApi.rechargeAddress({
        coin_name: selectedCoin.coinName
      }).then(res => {
        setLoadingRechargeAddress(false)
        if(res.status === 200) {
          setRechargeAddress(res.data.addresses);
          setRechargeInfo(res.data.coinPrice);
        }
      }).catch(() => {
        setLoadingRechargeAddress(false)
      })
    }
  }, [selectedCoin])

  return (
    <CommonPage title={t('lbl_recharge')} flex className={styles.recharge}>
      <div className={styles.selectGroup}>
        {/* 选择主网 */}
        <div style={{width: '50%'}} className={styles.itemSelect}>
          <NetWorkChoose chooseNetWork={(network => {
            setSelectedNet(network);
          })}/>
        </div>
        {/* 选择币种 */}
        <div style={{width: '50%'}} className={styles.itemSelect}>
          <NetCoinChoose network={selectedNet} chooseCoin={(coin) => {
            setSelectedCoin(coin);
          }}/>
        </div>
      </div>
      <div className="h24"></div>
      {/* 二维码 */}
      <div className={styles.qrcodeBox}>
        {
          loadingRechargeAddress ? <SpinLoading /> : (
            <div className={styles.qrcode}>
              <QRCodeSVG value={rechargeAddress} />
            </div>
          )
        }
      </div>
      <div className="h24"></div>
      {/* 充值提示 */}
      <div className={styles.tip}>{t('lbl_recharge_tip', {net: selectedNet.blockChain, coin: selectedCoin.blockCoinName})}</div>
      <div className="h24"></div>
      {/* 充值信息 */}
      <div className={styles.rechargeInfo}>
        <div className={styles.rechargeInfoItem}>
          <div className={styles.rechargeInfoTitle}>{t('lbl_address')}</div>
          <div className={styles.rechargeInfoContent}>
            <span>{rechargeAddress}</span>
            <IconCopy className={styles.btnCopy} onClick={() => copyToClipboard(rechargeAddress)} />
          </div>
        </div>
        <div className={styles.rechargeInfoItem}>
          <div className={styles.rechargeInfoTitle}>{t('lbl_network')}</div>
          <div className={styles.rechargeInfoContent}>{selectedNet.blockChain}</div>
        </div>
        <div className={styles.rechargeInfoItem}>
          <div className={styles.rechargeInfoTitle}>{t('lbl_token')}</div>
          <div className={styles.rechargeInfoContent}>{selectedCoin.blockCoinName}</div>
        </div>
{/*         <div className={styles.rechargeInfoItem}>
          <div className={styles.rechargeInfoTitle}>{t('lbl_estimated_time')}</div>
          <div className={styles.rechargeInfoContent}>10分钟</div>
        </div> */}
      </div>
    </CommonPage>
  );
})