/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-12-04 11:58:17
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2024-01-29 18:13:19
 * @FilePath: \weappf:\Workspace\NEW-WEB\neww-web-front\src\i18n\locales\zh.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-12-04 11:58:17
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2024-01-29 17:57:53
 * @FilePath: \weappf:\Workspace\NEW-WEB\neww-web-front\src\i18n\locales\zh.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default  {
  "hello": "你好",
  "common": {
    "register": "註冊賬號",
    "login": "登錄",
    "linkWallet": "連接錢包",
    "next": "繼續",
    "skip": "跳過",
    "opening": "暫未開放",
    "confirm": "確認",
    "cancel": "取消",
    "reset": "重置",
    "approve": "授權中...",
    "approveUsdt": "授權USDT",
    "success": "成功",
    "failure": "失敗",
    "address": "地址",
    "inputAddress": "輸入地址",
    "copy": "複製",
    "nextStep": "下一步",
    "back": "返回",
    "complete": "完成",
    "logout": "退出登錄",
    "tip": "提示",
    "lbl_no_data": "暫無數據",
  },
  "splash": {
    "connectWallet": "欢迎来到 NewWorld",
    "connectWalletDesc": "请链接钱包!",
    "1": "請連結您的數位錢包，開始使用我們的服務",
    "2": "全球一站式信用卡與支付解決方案",
    "3": "獲得實體信用卡，體驗全球無縫支付",
    "4": "通過主流虛擬貨幣理財額度，輕鬆提升信用卡額度",
    "5": "消費返利獎勵，輕鬆賺取積分，跨國通用",
    "6": "開始使用"
  },
  'pool':{
    "history":"收益明細",
    "record":"探索記錄",
    "rule":"活動規則",
    "prop":"道具",
    "explore":"開始探索",
    "exploration":"終止探索",
    "exploredTime":"已探索時間",
    "none":"未擁有NFT",
    "level":"關卡",
    "active":"激活",
    "activating":"激活中", 
    "waiting":"等待激活", 
    "myProduce":"我的產能值", 
    "totalIncome":"累計收益", 
    "settleContribution":"當前可結算貢獻值",
    "totalContribution":"累計獲得貢獻值",
    "winRate":"當前勝率",
    "upgradation":"升級",
    "buy":"購買",
    "time":"時間",
    "state":"狀態",
    "levelNum":"關卡數",
    "contributionScore":"貢獻分",
    "earn":"收益",
    
    "coinPrice":"{{tokenName}}價格",
    "currentCoinPrice":"當前幣價",
    "password":"密碼",
    "passwordInput":"請輸入密碼",
    "actualPrice":"實際支付價格",
    "countdown":"倒計時",
    "number":"數量",
    "tips":"用戶購買NFT時 50%支付的U，根據採集的{{tokenName}}幣價兌換為對應的{{tokenName}}數值",
    "day":"白天",
    "night":"晚上",
    "activationTime":"可激活時間為",
    "progress":"戰場中",
    "successExit":"成功退出",
    "failExit":"失敗退出",
    "activeExit":"手動退出",
    "income":"收入"
  },
  "nft": {
    "myNFT": "我的NFT",
    "buyHero": "購買英雄NFT",
    "updateHero": "升級英雄NFT",
   
    "buySkin": "購買素材NFT",
    "rare": "稀有度", 
    "R": "普通",
    "SR": "高級",
    "SSR": "史詩",
    "name": "NFT名稱",
    "number": "編號",
    "sort": "排序",
    "type": "類型",

    "hero":"英雄",
    "heroNFT": "英雄NFT",
    "heroR": "普通英雄",
    "heroSR": "高級英雄",
    "heroSSR": "史詩英雄",

    "skin": "素材",
    "skinNFT": "素材NFT",
    "skinR": "普通素材",
    "skinSR": "高級素材",
    "skinSSR": "史詩素材",

    "hot": "熱銷中",
    "upgradation": "前往升級",
    "extract": "提取",
    "compose": "合成",
    "transfer": "轉讓",

    "stock": "庫存",
    "price": "價格"
  },

  "lbl_home": "首頁",
  "lbl_mine": "我的",
  "lbl_battlefield": "空間戰場",
  "lbl_ranking": "排行榜",
  "lbl_mining_pool": "產能池",
  "lbl_send_code": "發送驗證碼",
  "lbl_chooseLanguage": '選擇語言',
  "lbl_placeholder_email": "郵箱地址",
  "lbl_placeholder_emailcode": "郵箱驗證碼",
  "lbl_placeholder_password": "設置密碼",
  "lbl_placeholder_confirm_password": "再次確認密碼",
  "lbl_placeholder_invite_code": "邀請碼",
  "lbl_placeholder_username": "用戶名",
  "lbl_placeholder_nickname": "暱稱",
  "msg_empty_invite_code": "邀請碼不能為空",
  "msg_empty_email": "郵箱地址不能為空",
  "msg_empty_emailcode": "郵箱驗證碼不能為空",
  "msg_empty_password": "密碼不能為空",
  "msg_empty_confirm_password": "確認密碼不能為空",
  "msg_incorrect_confirm_password": "兩次輸入的密碼不一致",
  "msg_incorrect_password": "密碼8-16位，包含字母大小寫與數字",
  "msg_incorrect_email": "請檢查郵箱地址是否正確",
  "lbl_register_success": "註冊成功",
  "msg_register_success": "賬號註冊成功",
  "msg_register_success_desc": "開始你的NewWorld之旅！",

  "lbl_my_account": "我的賬戶",
  "lbl_modify_nickname": "修改暱稱",
  "lbl_modify_password": "修改密碼",
  "lbl_account_management": "賬戶管理",
  "lbl_current_account": "當前賬戶",
  "lbl_account_list": "賬戶列表",
  "lbl_main_account": "主賬戶",
  "lbl_create_sub_account": "創建子賬戶",
  "lbl_placeholder_nickname_2_14": "暱稱 (2-14個字符)",
  "lbl_sub_account_desc": "*所有的子賬戶共用主賬戶密碼",
  "lbl_delete_sub_account": "刪除子賬戶",
  "lbl_delete_sub_account_confirm": "是否刪除子賬戶？",
  "lbl_switch_sub_account": "切換子賬戶",
  "lbl_switch_account": "切換賬戶",
  "lbl_switch_account_desc": "切換使用賬戶為",
  "lbl_my_invitation": "我的邀請",
  "lbl_invitation_qrcode": "邀請二維碼",
  "lbl_my_invitation_code": "我的邀請碼",
  "lbl_invitation_link": "邀請鏈接",
  "lbl_invitation_rules": "邀請規則說明",
  "lbl_language_switch": "語言切換",
  "msg_password_changed": "密碼修改成功",
  "msg_incorrect_nickname": "請輸入暱稱，2-14個字符",
  "msg_nickname_changed": "暱稱修改成功",
  "lbl_create_sub_account_tip": "*所有的子賬戶共用主賬戶密碼",
  "msg_logout_confirm": "是否退出登錄？",
  "msg_copy_success": "複製成功",
  "lbl_invite_tip": "*邀請好友註冊，可獲得額外獎勵",

  "lbl_my_wallet": "我的錢包",
  "lbl_my_score": "我的分數",
  "lbl_contribution_score": "貢獻分",
  "lbl_contribution_score_desc": "空間戰產出的累計值",
  "lbl_team_score": "團戰分",
  "lbl_team_score_desc": "傘下以及自己的貢獻統計為團戰分，排行榜以累計貢獻分分進行評比",
  "lbl_my_assets": "我的資產",
  "lbl_withdraw": "提現",
  "lbl_recharge": "充值",
  "lbl_wallet_record": "記錄",
  "lbl_choose_mainnet": "選擇主網",
  "lbl_choose_currency": "選擇幣種",
  "lbl_withdraw_address": "提現地址",
  "lbl_withdraw_amount": "提現數量",
  "lbl_balance": "餘額",
  "lbl_withdraw_fee": "手續費",
  "lbl_actual_account": "實際到賬數量",
  "lbl_network": "網絡",
  "lbl_address": "地址",
  "lbl_token": "代幣",
  "lbl_estimated_time": "預估時間",
  "lbl_recharge_tip": "*請僅通過{{net}}網絡進行{{coin}}充值，使用其他方式可能導致資產丟失",
  "lbl_input_wallet_address": "輸入錢包地址",
  "msg_empty_wallet_address": "錢包地址不能為空",
  "lbl_input_min_withdraw_amount": "最低 {{amount}}{{coin}}",

  "lbl_typeInOut": "收支",
  "lbl_type": "類型",
  "lbl_time": "時間",
  "lbl_amount": "數量",
  "lbl_assets": "資產",
  "lbl_sort": "排序",
  "lbl_startTime": "開始時間",
  "lbl_endTime": "結束時間",
  "lbl_max": "最大",
  "lbl_min": "最小",
  "lbl_income": "收入",
  "lbl_expenditure": "支出",
  "lbl_receive": "領取",
  "lbl_consume": "消費",
  "lbl_timeAsc": "時間升序",
  "lbl_timeDesc": "時間降序",
  "lbl_soon": "敬請期待",

  "lbl_nft_market": "NFT市場",
  "lbl_my_props": "我的道具",
  "lbl_blind_box_market": "盲盒商場",
  "lbl_my_team": "我的團隊",
  "msg_create_sub_account_success": "子帳戶創建成功",
  "msg_verify_code_sent": "驗證碼已發送",
  "msg_incorrect_phone": "請檢查手機號是否正確",
  "lbl_get_code_again": "重新獲取",
  "msg_change_password_success": "密碼修改成功",
  "lbl_team_ranking": "團戰排行榜",
  "lbl_upgrade": "升級",
  "lbl_price": "價格",
  "lbl_my_produce": "我的產能值",
  "lbl_total_income": "累計收益",
  "lbl_second": "第二名",
  "lbl_third": "第三名",
  "lbl_first": "第一名",
  "msg_switch_account_success": "帳戶切換成功",
  "lbl_go_to_buy_nft": "前往購買NFT",
  
  "msg_input_min_value": "請輸入有效的最低金額",
  "msg_withdraw_success": "提現成功",
  "msg_input_numbers_only": "只能輸入數字",
  "msg_withdraw_amount_not_empty": "提現數量不能為空",
  
  "lbl_buy": "購買",
  "lbl_pool_income": "產能池收益",
  "lbl_battlefield_income": "空間戰場收益",
  
  "lbl_sort_time_asc": "時間升序",
  "lbl_sort_time_desc": "時間降序",
  
  "lbl_sort_earn_asc": "收益升序",
  "lbl_sort_earn_desc": "收益降序",
  "lbl_sort_score_asc": "貢獻分升序",
  "lbl_sort_score_desc": "貢獻分降序",
  "lbl_sort_id_asc": "編號升序",
  "lbl_sort_id_desc": "編號分降序",
  "lbl_sort_level_asc": "稀有度升序",
  "lbl_sort_level_desc": "稀有度降序",
  "lbl_sort_price_asc": "價格升序",
  "lbl_sort_price_desc": "價格降序",
  "lbl_input_nft_name": "輸入NFT名稱",
  "lbl_input_nft_id": "輸入NFT編號",
  
  "lbl_withdraw_ing": "提現中",
  "lbl_withdraw_success": "提現成功",
  "lbl_withdraw_fail": "提現失敗",
  "lbl_team_consume": "團隊累計消費金額",
  "lbl_my_team_num": "我的下級({{num}}人)",
  "lbl_produce_introduce": "用戶購買NFT時 50%支付的U，根據採集的{{tokenName}}幣價兌換為對應的{{tokenName}}數值",
  "lbl_my_level": "我的等級",
  "lbl_my_community_capacity": "我的社區產能",
  "lbl_my_capacity_bonus": "我的產能加成",
  "lbl_ecology_score": "生態積分",
  "lbl_modify_avatar": "修改頭像",
  "lbl_copy_address": "複製地址",
  "lbl_disconnect": "斷開連接",
  "lbl_exchange": "兌換",
  "lbl_actual_amount": "到賬數量",
  "lbl_exchange_num": "可兌換數量",
  "lbl_current_price": "當前價格",
  "lbl_input_exchange_num": "輸入兌換數量",
  "lbl_password": "密碼",
  "lbl_input_password": "請輸入密碼",
  "lbl_exchange_record": "兌換記錄",
  "msg_exchange_success": "兌換成功",
  "lbl_user_id": "用戶ID",
  "msg_connect_wallet": "請先連接錢包",
  "lbl_unsupported": "不支持",
  
  "lbl_my_community": "我的社區",
  "lbl_referrer_num": "推薦人數",
  "lbl_my_subordinate_performance": "我的下級業績",
  "lbl_my_subordinate_num": "我的下級總人數",
  "lbl_my_subordinate_consume": "下級總累計消費金額",
  "lbl_my_subordinate_list": "我的下級列表",
  "lbl_my_referrer_consume": "推薦累計消費金額",
  "lbl_my_subordinate_performance_total": "下級總業績",
  "lbl_my_consume": "本人累計消費金額",
  "lbl_my_referrer_num": "推薦人數",
  "lbl_my_referrer_consume_total": "推薦累計消費金額",
  "lbl_subordinate_level": "{{level}}級下級",
  "lbl_id_search": "ID查詢",
  "lbl_id_search_tip": "*只可查詢您的下級用戶ID",
  "lbl_input_subordinate_id": "請輸入您的下級ID",
  "lbl_check_level_performance": "查看級別業績",
  "lbl_input_level": "請輸入級別",
  "lbl_max_level": "當前最大級別數: {{level}}",
  "msg_input_level": "請輸入有效的級別，只能是整數",
  "lbl_community_setting": "社區設置",
  "lbl_modify_name": "修改名稱",
  "lbl_modify_icon": "修改圖標",
  "lbl_input_new_team_name": "請輸入新的團隊名稱",
  "msg_incorrect_team_name": "請輸入有效的團隊名稱，2-14個字符",
  "msg_modify_team_name_success": "團隊名稱修改成功",
  "lbl_my_subordinate_num_level": "我的{{level}}級總人數",
  "lbl_my_subordinate_consume_level": "{{level}}級累計消費金額",
  "lbl_my_subordinate_consume_level_tip": "*只統計當前級別所有用戶的累計消費金額",
  "lbl_my_subordinate_level": "我的{{level}}級",
  "lbl_people": "人",
  "msg_level_over_max": "査詢級別不能超過最大級別",
  "lbl_placeholder_account": "用戶名（請輸入5-16位字符）",
  "msg_empty_account": "用戶名不能為空",
  "msg_incorrect_account": "請輸入有效的用戶名"

}
