/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-12-04 11:58:18
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2024-03-11 13:32:03
 * @FilePath: \weappf:\Workspace\NEW-WEB\neww-web-front\src\service\config.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
// 本地测试API
const devBaseURL = 'http://localhost:3000';
const proBaseURL = 'http://localhost:3000';

export const BASE_URL =
process.env.NODE_ENV === 'development' ? devBaseURL : proBaseURL;
export const TIMEOUT = 8000;
/* 测试 */
// 公钥
// export const publicKey = '-----BEGIN PUBLIC KEY-----\nMIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAx4drjGv+QZno6Sft51tyQwpuVYoDM7hg159ushl2W6FHJJczL2evQAJK4vQ33tembh0m22XFmEET7X7O22bhYAfNkHFTPlrKJHdpqt/i31refA7YyyAyj458jj9ASfUnmZsmtoo51TxUWG6DxM018QXQmQBeiresxVXW8TWK3MiQlfjOuUdV3j/QMjijhpOiCthIPZBhqOVSnZIKb9CgJChVS/s92S3kUrflMpbErv240m4zPG97ifYV1F1NOb3wY0s+WeyefxmJmnBibzLnHhpNY0YEEmirWNeWHsUxFf09On/5rQugLA/ABd2cRiVzd9XdoikyHiHIslKQkL2vOgOQbYszeAJ7I1oAr88OrqFHme21DuCUXQfwxgBZ89KUpAxvOeqkDo6c3lYHCV/KaUBomHkgfN+tMq/7D3C5UYAfCGIrMbMb25AZIwFCYaBHE52xCyOo6dE1orxqQ+ff+Ee3kzktr9CoNF4l5DcvbYhYVs14keVCJW03rK3T9nD68GtPIPG6qH5AId7XBFRhHeqiTUAoTF72ByVPnXMUNIWbKetZlqZy6U2ur4eGTibjlCtF6ewx5eUuEk1iR9qvixP3ESyfIfwucrHMVGBWV4Ub7ICSu1Q74aIMNkAzSRNgbzt+TlEs8i/8gaN+Hs84ooH3AOhdBrEBs+xNKY9iCP8CAwEAAQ==\n-----END PUBLIC KEY-----';
// // MD5加密的concatString
// export const concatString = '0D1eMvTVfbehtAbP5RJTFwzanTANZedx';
// export const storeId = 10000;

/* 正式 */
// 公钥
export const publicKey = '-----BEGIN PUBLIC KEY-----\nMIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAp1iLViKlQBuz+c7uY7t7lWCqBYGcBtpCXJfGjLPG+nPniFA/wl4/UxRQgQ/H9aNR6Lorebi+O2yCeitGmGg5yyPGUEJUGPqAPU3reQ+VwL94PE6vvruAQ085Lce8a3yt5YGjeQFHDrBkzruD7mD1tM3Hc5BCEj23yldBWr5S3os+97AakRpRipCgktvGFFZMnf/raDfmIJi8i8RvbeKtYcuTpjDmSJLqzE7SI4ivKb3FlEIbxYeUJQ79/zEPLvtx/AxQoBFXkBdXfefjv+2wACMrv4oid8DS4Z4fGzVbx3jVI0sMNvCKBcd4W6zJN1BqNhT3/RLQdbCOA/K9kYriGYBguo8XC9IxSnPYiKhsDPewWQ91zBQBapC2FWgEF0C3mlfCc9xUvLdUmP37BYNJ4HnpFvz/ZoZXeE7hUJP/j6XSUMRMJ9FVcMuuqVs11I0Ik3iQRdUH9dyFrIPaVFn8eBpB9b2xS4iuN3ZsXIExJ8tiIhZ/IjMAfFlqgh7auaOdwCWAs3O+dcmk4+IhlYm0gEvq9AUTNgHced7MvBd2lzNgO1kfrBhSQnnhrD9Q9GmypYhH623ZRKS4s+WXIf4p5EsK1/x4pFDDOaSeW7GWlH24SCQ1aOgDTCDa1jxE5HwBJfdX22sM6zM4WMObqG+rgJyIBtxbbYm7utR9ZMQucrUCAwEAAQ==\n-----END PUBLIC KEY-----';
export const concatString = '2Yjk2mbJsRleP0e81YQtljcPQImpXV';
export const storeId = 10000;