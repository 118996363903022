import React from 'react';
import styles from './HomeNavBar.module.scss';
import imageLogo from 'assets/images/logo.png';
import {useNavigate} from "react-router-dom";
import classNames from "classnames";
import WalletAction from "@/components/wallet/walletAction/WalletAction";

const HomeNavBar = (props) => {
  let navigate = useNavigate();
  const contentStyle = classNames(styles.content, {
    [styles.contentFixed]: props.fixed,
  });

  return (
    <div className={contentStyle}>
      <img src={imageLogo} className={styles.logo} alt="" />
      {/* <div className={styles.center}>
        <img src={iconSearch} className={styles.icon} alt="" onClick={() => {
          navigate('/search')
        }}/>
        <img src={iconNotification} alt="" className={styles.icon} onClick={() => navigate('/messages')} />
      </div> */}
      <div className={styles.right}>
        <WalletAction></WalletAction>
      </div>
    </div>
  );
};

HomeNavBar.defaultProps = {
  fixed: true,
};

export default HomeNavBar;