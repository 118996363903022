// 默认布局
import React, {useEffect, useState} from 'react';
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {TabBar} from "antd-mobile";
import styles from './defaultLayout.module.scss';
import {useTranslation} from "react-i18next";
import { ReactComponent as IconHome } from '@icon/nav_home.svg';
import { ReactComponent as IconBattlefield } from '@icon/nav_battlefield.svg';
import { ReactComponent as IconMining } from '@icon/nav_mining.svg';
import { ReactComponent as IconMine } from '@icon/nav_mine.svg';


const DefaultLayout = ({ children }) => {
  const [selectedMenu, setSelectedMenu] = useState('home');
  let {t} = useTranslation();
  let navigate = useNavigate();
  let location = useLocation();

  const menuChange = (key) => {
    // console.log(key);
    // setSelectedMenu(key);
    navigate('/' + key);
  }

  useEffect(() => {
      // console.log(location.pathname)
      let key = location.pathname.replace('/', '');
      if (tabs.filter(item => item.key === key).length === 0) {
          return;
      }
      setSelectedMenu(key);
  }, [location.pathname])

  const tabs = [
    {
      key: 'home',
      // title: t('lbl_home'),
      icon: <IconHome width={24} height={24} />,
    },
    {
      key: 'miningPool',
      // title: t('lbl_mining_pool'),
      icon: <IconMining width={24} height={24} />,
    },
    {
      key: 'battlefield',
      // title: t('lbl_battlefield'),
      icon: <IconBattlefield width={24} height={24} />,
    },
    {
      key: 'mine',
      // title: t('lbl_mine'),
      icon: <IconMine width={24} height={24} />,
    },
  ];
  return (
    <div style={{height: '100%'}}>
      <div className={styles.content}>
        {/*渲染子页面*/}
        <Outlet/>
      </div>
      <TabBar className={styles.tabbar} activeKey={selectedMenu} onChange={menuChange}>
        {tabs.map(item => (
          <TabBar.Item key={item.key} icon={item.icon} title={item.title}/>
        ))}
      </TabBar>
    </div>
  );
};

export default DefaultLayout;