/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-05-08 22:20:05
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-05-18 15:00:39
 * @FilePath: \weapp\wepay-h5\src\pages\account\register\Register.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React, {useEffect, useRef, useState, memo} from 'react';
import CommonNavBar from "@/components/commonNavBar/CommonNavBar";
import styles from './Register.module.scss';
import { Form, Modal } from "antd-mobile";
import CommonButton from "@/components/commonButton/CommonButton";
import CommonPwdInput from "@/components/commonPwdInput/commonPwdInput";
import CommonInput from "@/components/commonPwdInput/commonInput";
import CommonSendCode from "@/components/commonSendCode/CommonSendCode";
import {useNavigate} from "react-router-dom";
import CommonPage from "@/components/commonPage/page/CommonPage";
import accountApi from "@/service/accountApi";
import {useAccount, useSignMessage, useConnect} from "wagmi";
import {toastError, toastHide, toastLoading, toastSuccess } from "@/utils/Toast";
import {checkName, checkPassword} from "@/utils/utils";
import {useTranslation, Trans} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {setTempDate} from "@/redux/store/app";
import md5 from "md5";
import { ReactComponent as BtnSwitchLang } from '@icon/lan.svg';
import LangPopup from "@/components/langPopup/LangPopup";
import toast from "react-hot-toast";
import { useWeb3Modal } from '@web3modal/wagmi/react'

export default memo(function Register() {
  const {address, isConnected} = useAccount();
  const { open } = useWeb3Modal()

  const {connect, connectors, isLoading, pendingConnector} = useConnect({
    onError: (error) => {
      toast.error(error.message);
    },
    onSuccess: (data) => {
      
    }
  })
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const {t} = useTranslation();

  const {signMessageAsync} = useSignMessage();
  const [form] = Form.useForm()
  const [langVisible, setLangVisible] = useState(false);

  // 获取url上的参数
  const params = new URLSearchParams(window.location.search);
  const inviteCode = params.get('invite_code');
  if(inviteCode) {
    form.setFieldsValue({
      inviteCode: inviteCode
    })
  }

  const onFinish = (values) => {
    // 普通注册
    registerAccount();
  }
  const onFinishFailed = (errorInfo) => {
    if(!address) {
      Modal.alert({
        title: t('common.tip'),
        content: t('msg_connect_wallet'),
        confirmText: t('common.confirm'),
        onConfirm: () => {
          open()
        }
      })
    }
  }

  // 密码验证
  const passwordValidator = (_, value) => {
    if (!value || checkPassword(value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(t("msg_incorrect_password")));
  };

  // 过滤相同的connector
  const filteredConnectors = () => {
    let uniqueNames = {};
    let filterData = connectors.filter((item) => {
      if (!uniqueNames[item.name]) {
        uniqueNames[item.name] = true;
        return true;
      }
    });
    return filterData;
  }


  useEffect(() => {
    if(!isConnected) {
      Modal.alert({
        title: t('common.tip'),
        content: t('msg_connect_wallet'),
        confirmText: t('common.confirm'),
        onConfirm: () => {
          open();
        }
      })
    }
  }, [isConnected])

  const registerAccount = () => {
    if(!address) {
      toastError(t('msg_connect_wallet'));
      return;
    }
    form.validateFields().then(values => {
      toastLoading();
      accountApi.getNonce(address)
        .then(res => {
          if(res.status === 200) {
            return signMessageAsync({message: res.data.nonce})
          }
        })
        .then(data => {
          const pwd = md5(values.pay_pwd);
          return accountApi.register({
            account: values.account,
            bindAddress: address,
            payPwd: pwd,
            sign: data,
            inviteCode: values.inviteCode
          });
        })
        .then(() => {
          toastHide();
          dispatch(setTempDate({}))
          navigate('/registerSuccess');
        })
        .catch(err => {
          toastHide();
          // toastError(err);
          console.log(err)
        });
    });
  }

  const RightButton = () => {
    return <BtnSwitchLang className={styles.btnLan} onClick={() => navigate('/setup/locales') } />;
  }

  useEffect(() => {
  }, [])

  return (
    <CommonPage title={t('common.register')} flex className={styles.register} header={<CommonNavBar right={<RightButton/>}>{t('common.register')}</CommonNavBar>}>
      <Form className={styles.form} form={form} onFinish={onFinish} onFinishFailed={onFinishFailed} footer={
        <>
        <div className="h25"></div>
        <CommonButton block type='submit' color='primary'>{t('common.confirm')}</CommonButton>
        </>
      }>
        {/* 邮箱地址 */}
        <div className={styles.formItem}>
          <Form.Item name='account' rules={[{
            validator: (_, value) => {
              if (!value) {
                return Promise.reject(t('msg_empty_account'));
              } else {
                // 长度限制 中文1个算两个字符 英文1个算一个字符 5-16个字符
                if (value.replace(/[^\x00-\xff]/g, 'xx').length < 5 || value.replace(/[^\x00-\xff]/g, 'xx').length > 16) {
                  return Promise.reject(t('msg_incorrect_account'));
                } else {
                  return Promise.resolve();
                }
              }
            }
          }]}>
            <CommonInput placeholder={t('lbl_placeholder_account')} iconLeft={null} />
          </Form.Item>
        </div>
        {/* 登录密码 */}
        <div className={styles.formItem}>
          <Form.Item name={'pay_pwd'} rules={[{ required: true, message: t('msg_empty_password') }, { validator: passwordValidator }]}>
            <CommonPwdInput placeholder={t('lbl_placeholder_password')} iconLeft={null} />
          </Form.Item>
        </div>
        {/* 确认密码 */}
        <div className={styles.formItem}>
          <Form.Item name={'confirmPassword'} rules={[
            {
              required: true,
              message: t('msg_empty_confirm_password'),
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('pay_pwd') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(t('msg_incorrect_confirm_password')));
              },
            }),
            { validator: passwordValidator }
          ]}>
            <CommonPwdInput placeholder={t('lbl_placeholder_confirm_password')} iconLeft={null} />
          </Form.Item>
        </div>
        {/* 邀请码 */}
        <div className={styles.formItem}>
          <Form.Item
            name="inviteCode"
            rules={[{ required: true, message: t('msg_empty_invite_code') }]}
          >
            <CommonInput placeholder={t('lbl_placeholder_invite_code')} />
          </Form.Item>
        </div>
      </Form>
      {/* 切换语言底部弹出层 */}
      <LangPopup visible={ langVisible } getVisible={ setLangVisible } />
      {/* 连接钱包弹出层 */}
    </CommonPage>
  );
})