/*
 * @Author: 叹息桥丶 648161532@qq.com
 * @Date: 2023-05-06 16:50:13
 * @LastEditors: 叹息桥丶 648161532@qq.com
 * @LastEditTime: 2023-12-16 17:19:26
 * @FilePath: 
 * @Description: 组件描述
 */
import './CommonPopup.scss';
import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";

import {Popup} from "antd-mobile";
import PropTypes from "prop-types";
import classNames from 'classnames';

const CommonPopup = (props) => {
    const [visible, setVisible] = useState(false);
    const {title, getVisible, children,footer} = props;
    let theme = useSelector(state => {
        return state.app.theme;
    });
    useEffect(() => {
        setVisible(props.visible);
    }, [props.visible]);
    const handleCancel = () => {
        setVisible(false);
        getVisible&&getVisible(false)
    }

    return (<Popup
        className={classNames(theme)}
        visible={visible}
        onMaskClick={() => {
            handleCancel();
        }}
        onClose={() => {
            handleCancel();
        }}
    >
        <div className='dialog-conetnt'>
            <div className='operate' onClick={() => {
                handleCancel();
            }}>
                <div className='action'></div>
            </div>
            <div className='header'>
                <div className='title formTitle'>{title}</div>
            </div>
            <div className='content'>
                {/* {props.contnet} */}
                {children}
            </div>

            { footer && <div className='footer'>{footer} </div>}
        </div>
    </Popup>);
};

CommonPopup.prototype = {
    title: PropTypes.string,
    visible: PropTypes.bool,
    getVisible: PropTypes.func,
};

export default CommonPopup;